import dayjs from "dayjs";
import { motion } from "framer-motion";
import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { getFormattedDate } from "../../shared/utils/formatting";
import { CalendarDay, Local, ReservationTable } from "../../types";
import { useTranslation } from "react-i18next";
import Icon from "../Icon";
import { IsSafari } from "../../shared/utils/customfunc";
import { useQuery } from "react-query";
import { fetchRevenueData } from "../../shared/queries";
import RevenueTooltip from "./RevenueTooltip";
import Button from "../Button";
import RevenueModal from "./RevenueModal";
import PerRoomRate from "./channel/perRoom/PerRoomRate";
import Availability from "./channel/Availability";
import PerPersonRate from "./channel/perPerson/PerPersonRate";
import { setModalValueOverride } from "../../features/channelManager/channelManagerSlice";
import { useDispatch } from "react-redux";

type ApartmentRowProps = {
  local: Local;
  days: CalendarDay[];
  startDate: string;
  reservations: ReservationTable[];
  onView: (r: ReservationTable) => void;
  selectStart: any;
  objChannel: any;
  refetchRestrictions: any;
  viewDays: number;
  findActiveRevenue?: boolean;
  selectedFilter?: any;
};

export default function ApartmentRow({
  selectStart,
  startDate,
  local,
  days,
  reservations,
  onView,
  objChannel,
  viewDays,
  refetchRestrictions,
  findActiveRevenue,
  selectedFilter,
}: ApartmentRowProps) {
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch<any>();
  const [reservationList, setReservationList] = useState<any>(reservations);
  const [tempObj, setTempObj] = useState<any>();
  const [viewMore, setViewMore] = useState(false);
  // availability
  const [isDragging, setIsDragging] = useState(false);
  // selezione giorni per resv
  const [selectedDays, setSelectedDays] = useState<any>([]);
  const [isDraggingDays, setIsDraggingDays] = useState(false);
  const [startPointDays, setStartPointDays] = useState({ x: 0, y: 0 });
  const containerRefDays = useRef(null);
  const [viewAllPerPerson, setViewAllPerPerson] = useState<string[]>([]);
  // selezione price su timeline
  const [isDraggingPrice, setIsDraggingPrice] = useState({
    type: "",
    active: false,
  });
  const [chainedChannel, setChainedChannel] = useState<string[]>([]);
  const containerRefPrice: any = useRef([]);
  const containerRefMinStayArrival: any = useRef([]);
  const containerRefMaxStay: any = useRef([]);
  const containerRefClosedToArrival: any = useRef([]);
  const containerRefClosedToDeparture: any = useRef([]);
  const containerRefStopSell: any = useRef([]);

  const [selectedDivsPrice, setSelectedDivsPrice] = useState<any>([]);
  const [startPointPrice, setStartPointPrice] = useState({ x: 0, y: 0 });
  const [revenueModal, setRevenueModal] = useState<any>({
    x: 0,
    y: 0,
    display: false,
  });

  const sizeOfResv = (length: any, fromToday: any, viewDays: number) => {
    switch (viewDays) {
      case 3:
        return {
          width: (length / viewDays) * 100 - 1,
          marginLeft: (fromToday / viewDays) * 100 + 3,
        };
      case 7:
        return {
          width: (length / viewDays) * 100 - 1,
          marginLeft: (fromToday / viewDays) * 100 + 3,
        };
      case 21:
        return {
          width: (length / viewDays) * 100 - 1,
          marginLeft: (fromToday / viewDays) * 100 + 3,
        };
      case 31: // OK
        return {
          width: (length / viewDays) * 100 + 1,
          marginLeft: (fromToday / viewDays) * 100 + (fromToday / viewDays) * 2,
        };
      default:
        return {
          width: (length / viewDays) * 100 - 1,
          marginLeft: (fromToday / viewDays) * 100 + 3,
        };
    }
  };

  const [revenueModalEdit, setRevenueModalEdit] = useState<{
    display: boolean;
    local_key: string;
    local_name: string;
  }>({
    display: false,
    local_key: "",
    local_name: "",
  });

  const { data: revenueManager, refetch: refetchRevenueManager } = useQuery(
    ["revenueManager", local.local_key],
    () => fetchRevenueData(local.local_key!),
    {
      onSuccess: (data) => {
        return data;
      },
      enabled: !!findActiveRevenue,
    }
  );

  // Funzione per avviare la selezione
  const handleMouseDownDays = (e: any) => {
    setIsDraggingDays(true);
    setStartPointDays({ x: e.clientX, y: e.clientY });
    const container: any = containerRefDays.current;
    if (!container) return;
    // Coordinate del trascinamento relative al container
    const currentPoint = { x: e.clientX, y: e.clientY };
    const left = Math.min(startPointDays.x, currentPoint.x);
    const right = Math.max(startPointDays.x, currentPoint.x);
    const top = Math.min(startPointDays.y, currentPoint.y);
    const bottom = Math.max(startPointDays.y, currentPoint.y);
    const selectedIds: any = [...selectedDays];
    // Controlla quali div sono all'interno dell'area di selezione
    Array.from(container.children).forEach((div: any, index) => {
      const rect = div.getBoundingClientRect();
      if (
        rect.left < right &&
        rect.right > left &&
        rect.top < bottom &&
        rect.bottom > top
      ) {
        const idsFinded = selectedIds.find(
          (id: string) => id === e.target.textContent
        );
        if (idsFinded) {
          const isBefore = dayjs(e.target.textContent).isBefore(
            selectedIds[selectedIds.length - 1]
          );
          if (isBefore && selectedIds.length > 1) {
            selectedIds.pop();
          }
        } else {
          selectedIds.push(e.target.textContent);
        }
      }
    });
    setSelectedDays(selectedIds);
  };

  // Funzione per aggiornare la selezione durante il trascinamento
  const handleMouseMoveDays = (e: any) => {
    if (!isDraggingDays) return;
    const container: any = containerRefDays.current;
    if (!container) return;
    // Coordinate del trascinamento relative al container
    const currentPoint = { x: e.clientX, y: e.clientY };
    const left = Math.min(startPointDays.x, currentPoint.x);
    const right = Math.max(startPointDays.x, currentPoint.x);
    const top = Math.min(startPointDays.y, currentPoint.y);
    const bottom = Math.max(startPointDays.y, currentPoint.y);
    const selectedIds: any = [...selectedDays];
    // Controlla quali div sono all'interno dell'area di selezione
    Array.from(container.children).forEach((div: any, index) => {
      const rect = div.getBoundingClientRect();
      if (
        rect.left < right &&
        rect.right > left &&
        rect.top < bottom &&
        rect.bottom > top
      ) {
        const idsFinded = selectedIds.find(
          (id: string) => id === e.target.textContent
        );
        if (idsFinded) {
          const isBefore = dayjs(e.target.textContent).isBefore(
            selectedIds[selectedIds.length - 1]
          );
          if (isBefore && selectedIds.length > 1) {
            selectedIds.pop();
          }
        } else {
          selectedIds.push(e.target.textContent);
        }
      }
    });
    setSelectedDays(selectedIds); // Aggiorna la selezione
  };

  // Funzione per terminare la selezione
  const handleMouseUpDays = () => {
    if (!isDraggingDays) return;
    const dateFromSelected = selectedDays[0];
    const dateToSelected = selectedDays[selectedDays.length - 1];
    const isBefore = dayjs(dateFromSelected).isBefore(dateToSelected);
    let dateFrom = "";
    let dateTo = "";
    if (isBefore) {
      dateFrom = dateFromSelected;
      dateTo = dateToSelected;
    } else {
      dateFrom = dateToSelected;
      dateTo = dateFromSelected;
    }
    history.push(
      "/reservations/new?from=" +
        dateFrom +
        "&to=" +
        dateTo +
        "&local=" +
        local.local_key
    );
    setIsDraggingDays(false);
    setSelectedDays([]);
  };

  // Funzione per avviare la selezione
  const handleMouseDownRate = (e: any, type: string) => {
    if (
      e.target.textContent.includes("per_room") ||
      e.target.textContent.includes("per_person")
    ) {
      const newArrayTemp = e.target.textContent.split("|||");
      if (newArrayTemp[11] && newArrayTemp[11] !== "false") {
        setIsDraggingPrice({
          type: type,
          active: true,
        });
        setStartPointPrice({ x: e.clientX, y: e.clientY });
        setSelectedDivsPrice([]); // Resetta la selezione corrente
      }
    }
  };

  // Funzione per aggiornare la selezione durante il trascinamento
  const handleMouseMoveRate = (e: any, type: string) => {
    if (!isDraggingPrice.active) return;
    if (isDraggingPrice.type !== type) return;
    let container: any;
    switch (type) {
      case "min_stay_arrival":
        container = containerRefMinStayArrival.current;
        break;
      case "stop_sell":
        container = containerRefStopSell.current;
        break;
      case "closed_to_arrival":
        container = containerRefClosedToArrival.current;
        break;
      case "closed_to_departure":
        container = containerRefClosedToDeparture.current;
        break;
      case "max_stay":
        container = containerRefMaxStay.current;
        break;
      default:
        container = containerRefPrice.current;
        break;
    }
    if (!container) return;
    // Coordinate del trascinamento relative al container
    const currentPoint = { x: e.clientX, y: e.clientY };
    const left = Math.min(startPointPrice.x, currentPoint.x);
    const right = Math.max(startPointPrice.x, currentPoint.x);
    const top = Math.min(startPointPrice.y, currentPoint.y);
    const bottom = Math.max(startPointPrice.y, currentPoint.y);
    const selectedIdsPrice: any = [...selectedDivsPrice];
    // Controlla quali div sono all'interno dell'area di selezione
    container.forEach((div: any) => {
      const rect = div.getBoundingClientRect();
      if (
        rect.left < right &&
        rect.right > left &&
        rect.top < bottom &&
        rect.bottom > top
      ) {
        const newArrayTemp = e.target.textContent.split("|||");

        const idsFinded = selectedIdsPrice.find(
          (idsPrice: any) =>
            idsPrice.id === newArrayTemp[5] + newArrayTemp[3] + type
        );
        const isSameRatePlanId = selectedIdsPrice.find(
          (idsPrice: any) => idsPrice.ratePlanId === newArrayTemp[5]
        );
        if (idsFinded) {
          const isBefore = dayjs(newArrayTemp[3]).isBefore(
            selectedIdsPrice[selectedIdsPrice.length - 1].date
          );
          if (isBefore && selectedIdsPrice.length > 1) {
            selectedIdsPrice.pop();
          }
        } else if (isSameRatePlanId || selectedIdsPrice.length === 0) {
          if (newArrayTemp[11] && newArrayTemp[11] !== "false") {
            const newObjTemp = {
              rate: parseFloat(newArrayTemp[2]),
              date: newArrayTemp[3],
              title: newArrayTemp[4],
              ratePlanId: newArrayTemp[5],
              stopSell: newArrayTemp[6] === "true",
              closed_to_arrival: newArrayTemp[7] === "true",
              closed_to_departure: newArrayTemp[8] === "true",
              min_stay_arrival: newArrayTemp[9],
              max_stay: newArrayTemp[10],
              id: newArrayTemp[5] + newArrayTemp[3] + type,
              editable: newArrayTemp[11],
              key: newArrayTemp[12],
              revenueValue:
                newArrayTemp[0] !== "" ? parseFloat(newArrayTemp[0]) : null,
              type: type,
            };
            selectedIdsPrice.push(newObjTemp);
          }
        }
      }
    });
    setSelectedDivsPrice(selectedIdsPrice); // Aggiorna la selezione
  };

  // Funzione per terminare la selezione
  const handleMouseUpRate = (type: string) => {
    if (
      !isDraggingPrice.active ||
      isDraggingPrice.type !== type ||
      selectedDivsPrice.length === 0
    ) {
      setIsDraggingPrice({
        type: "",
        active: false,
      });
      setSelectedDivsPrice([]);
      return;
    }
    const lastSelected = selectedDivsPrice[selectedDivsPrice.length - 1];
    const dateFirstSelected = selectedDivsPrice[0]?.date;
    const dateSecondSelected = lastSelected?.date;
    const isBefore = dayjs(dateFirstSelected).isBefore(dateSecondSelected);
    let dateFrom = "";
    let dateTo = "";
    if (isBefore) {
      dateFrom = dateFirstSelected;
      dateTo = dateSecondSelected;
    } else {
      dateFrom = dateSecondSelected;
      dateTo = dateFirstSelected;
    }
    dispatch(
      setModalValueOverride({
        display: true,
        type: lastSelected.key,
        roomType: local.name,
        ratePlan: lastSelected?.title,
        ratePlanId: lastSelected.ratePlanId,
        restriction: type,
        currentPrice: parseFloat(lastSelected?.rate),
        priceValue: parseFloat(lastSelected?.rate),
        availabilityValue: 1,
        dateRange: {
          from: dateFrom,
          to: dateTo,
        },
        roomTypeId: tempObj?.room_type_id,
        propertyId: tempObj?.property_id,
        stopSellValue: lastSelected?.stop_sell === "true",
        closedToArrivalValue: lastSelected?.closed_to_arrival === "true",
        closedToDepartureValue: lastSelected?.closed_to_departure === "true",
        minStayValue: lastSelected?.min_stay_arrival
          ? lastSelected?.min_stay_arrival
          : lastSelected?.min_stay_through,
        maxStayValue: lastSelected?.max_stay,
        revenueValue: lastSelected?.revenueValue,
      })
    );
    setIsDraggingPrice({
      type: type,
      active: false,
    });
    setSelectedDivsPrice([]);
  };

  const setValueManager = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    revenueManagerTemp: any,
    specificDate: string,
    rateChannel: number
  ) => {
    if (revenueManagerTemp && revenueManagerTemp.results) {
      const revenueManagerTempFinded =
        revenueManagerTemp.results.payload.rates.find(
          (d: any) => d.date === specificDate
        );
      setRevenueModal({
        x: e.clientX,
        y: e.clientY,
        display: true,
        rate: revenueManagerTempFinded?.rate,
        dayofweek:
          revenueManagerTempFinded?.attribution?.day_of_week * rateChannel,
        demandScore:
          revenueManagerTempFinded?.attribution?.demand_score * rateChannel,
        holidayOrEvent:
          revenueManagerTempFinded?.attribution?.holiday_or_event * rateChannel,
        leadTime:
          revenueManagerTempFinded?.attribution?.lead_time * rateChannel,
        orphan: revenueManagerTempFinded?.attribution?.orphan * rateChannel,
        seasonality:
          revenueManagerTempFinded?.attribution?.seasonality * rateChannel,
        rateChannel: rateChannel,
      });
    }
  };

  const verifyMonitorStatus = (status: string) => {
    switch (status) {
      case "NOTINIT":
        return (
          <div
            data-tip={"Digital"}
            className={"w-2 h-2 rounded-full bg-purple-600"}
          ></div>
        );
      case "ERROR":
        return (
          <div
            data-tip={"Offline"}
            className={"w-2 h-2 rounded-full bg-red-600"}
          ></div>
        );
      case "ACTIVE":
        return (
          <div
            data-tip={"Online"}
            className={"w-2 h-2 rounded-full bg-cyan-600"}
          ></div>
        );
      default:
        break;
    }
  };
  const verifyBg = (status: string) => {
    switch (status) {
      case "WAIT":
        return "bg-red-200 text-gray-900 border-red-700";
      case "WAIT_DEPOSIT":
        return "bg-yellow-300 text-gray-900 border-yellow-700";
      case "NONEED":
        return "bg-gray-100 text-gray-900 border-gray-700";
      case "OK":
        return "bg-green-100 text-gray-900 border-green-700";
      case "PEND":
        return "bg-yellow-300 text-gray-900 border-yellow-700";
      case "BOOKING":
        return "text-gray-900 border-blue-700";
      case "AIRBNB":
        return "text-gray-900 border-red-700";
      default:
        break;
    }
  };
  const verifyStatus = (status: string) => {
    switch (status) {
      case "WAIT":
        return t("resv.notExecuted");
      case "WAIT_DEPOSIT":
        return t("resv.waitingDeposit");
      case "NONEED":
        return t("resv.notNecessary");
      case "OK":
        return t("resv.confirmed");
      case "PEND":
        return t("resv.waitingConfirmation");
      default:
        break;
    }
  };

  const logoImageComp = (source: string) => {
    switch (source) {
      case "AIRBNB":
        return (
          <img
            className={"w-5 h-5 rounded-full mr-1"}
            src="/images/logos/airbnbsmall.png"
            alt=""
          />
        );
      case "BOOKING":
        return (
          <img
            className={"w-5 h-5 rounded-full mr-1"}
            src="/images/logos/bookingsmall.png"
            alt=""
          />
        );
      default:
        break;
    }
  };

  useEffect(() => {
    if (
      objChannel?.results[local.local_key as keyof typeof objChannel.results]
    ) {
      setTempObj(
        objChannel?.results[local.local_key as keyof typeof objChannel.results]
      );
    } else {
      setTempObj(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [objChannel]);

  useEffect(() => {
    setReservationList(
      reservations.map((r) => {
        const length = dayjs(r.check_out, "YYYY-MM-DD").diff(
          dayjs(r.check_in, "YYYY-MM-DD"),
          "days"
        ); // +1
        const fromToday = dayjs(r.check_in, "YYYY-MM-DD").diff(
          dayjs(startDate),
          "days"
        );
        return {
          ...r,
          length,
          fromToday,
          width: sizeOfResv(length, fromToday, viewDays).width, //-9
          marginLeft: sizeOfResv(length, fromToday, viewDays).marginLeft, // +4
        };
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reservations, startDate]);

  useEffect(() => {
    if ((selectStart && selectStart.length < 0) || !selectStart) {
      setTempObj(null);
    }
    if (selectStart && selectStart.length >= 1) {
      setViewMore(true);
    }
  }, [selectStart]);

  return (
    <div className="my-10">
      {/* AVAILABILITY */}
      {tempObj &&
        Object.keys(tempObj)?.map((key) => {
          if (key === "availability") {
            return (
              <Availability
                days={days}
                keys={key}
                localName={local.name!}
                tempObj={tempObj}
                isDragging={isDragging}
                setIsDragging={setIsDragging}
              />
            );
          }
          return false;
        })}
      {/* reservations */}
      <div
        className="hover:bg-white flex border-t"
        style={{
          height:
            reservationList.length > 0
              ? `${reservationList.length * 24 + 64}px`
              : "128px", // 64px
        }}
      >
        <div className="bg-gray-100 z-50 md:text-sm text-xs font-medium text-gray-600 md:w-52 w-20 border-b md:py-2 md:px-5 md:p-0 p-1 border-r">
          <div className={" flex gap-1 items-center truncate"}>
            <div>
              {local.monitor_status &&
                verifyMonitorStatus(local.monitor_status)}
              <ReactTooltip />
            </div>

            <div className="whitespace-pre-wrap">{local.name}</div>
          </div>
          <div>
            {findActiveRevenue && (
              <Button
                icon="trending-up"
                padding="xs"
                size="responsive"
                label="Revenue manager"
                dataTip="revenue manager"
                iconSize="16px"
                onClick={() => {
                  setRevenueModalEdit({
                    display: true,
                    local_key: local.local_key as string,
                    local_name: local.name as string,
                  });
                }}
              />
            )}
          </div>
        </div>

        <div className="flex-1 border-b flex relative place-content-center">
          <div
            className="absolute w-full h-full top-0 left-0 bg-red flex"
            ref={containerRefDays}
            style={{ position: "relative", userSelect: "none" }}
            onMouseDown={handleMouseDownDays}
            onMouseMove={handleMouseMoveDays}
            onMouseUp={handleMouseUpDays}
          >
            {days.map((day, index) => {
              const specificDate = `${day.date.slice(6, 10)}-${day.date.slice(
                3,
                5
              )}-${day.date.slice(0, 2)}`;
              return (
                <div
                  onClick={() => {
                    history.push(
                      "/reservations/new?from=" +
                        day.date +
                        "&local=" +
                        local.local_key
                    );
                  }}
                  key={index}
                  className={`flex-1 h-full border-r hover:bg-gray-100 cursor-pointer`}
                  style={{
                    backgroundColor:
                      selectedDays.includes(specificDate) ||
                      (selectedDays.length > 0 &&
                        dayjs(specificDate).isAfter(selectedDays[0]) &&
                        dayjs(specificDate).isBefore(
                          selectedDays[selectedDays.length - 1]
                        ))
                        ? "lightblue"
                        : "transparent",
                  }}
                >
                  <span className="hidden">{specificDate}</span>
                </div>
              );
            })}
          </div>
          <ul>
            {reservationList.map((reservation: any, index: number) => {
              if (reservation.local_key) {
                return (
                  <React.Fragment key={reservation.code}>
                    <motion.li
                      initial={{
                        left: -90,
                      }}
                      animate={{
                        left: 0,
                      }}
                      style={{
                        width: reservation.width + "%",
                        marginLeft: reservation.marginLeft + "%",
                        marginTop:
                          reservationList.length > 0
                            ? index * 24 + 5
                            : index * 24, // 30
                      }}
                      className={`${
                        IsSafari()
                          ? "transform skew-x-20Minus"
                          : "transform skew-x-20Minus"
                      } absolute cst-shadow truncate px-4 cursor-pointer flex flex-nowrap items-center w-full h-6 p-1 font-medium text-xs border-2 ${verifyBg(
                        reservation.checkin_status
                      )} ${
                        (reservation?.source === "BOOKING" ||
                          reservation?.source === "AIRBNB") &&
                        verifyBg(reservation.source)
                      }`}
                      data-tip={`${reservation.source} - ${getFormattedDate(
                        reservation.check_in,
                        "YYYY-MM-DD",
                        "DD-MM-YYYY"
                      )} -> ${getFormattedDate(
                        reservation.check_out,
                        "YYYY-MM-DD",
                        "DD-MM-YYYY"
                      )} ${
                        reservation.guests_num
                          ? `- ${t("resv.guestCount")}: ${
                              reservation.guests_num
                            }`
                          : ""
                      } ${
                        reservation.tot_price
                          ? `- ${t(
                              "reservationNotes.price"
                            )}: ${reservation.tot_price.toFixed(2)}€`
                          : ``
                      }`}
                      data-for={`soclose${reservation.code}`}
                    >
                      <div
                        className={`w-full truncate ${
                          IsSafari() ? "" : "transform skew-x-20"
                        }`}
                      >
                        {reservation.source}
                        <span
                          className={
                            "border-white opacity-30 mx-1 border-r h-full"
                          }
                        ></span>
                        {getFormattedDate(
                          reservation.check_in,
                          "YYYY-MM-DD",
                          "DD-MM-YYYY"
                        )}
                        <span
                          className={
                            "border-white opacity-30 mx-1 border-r h-full"
                          }
                        ></span>
                        {getFormattedDate(
                          reservation.check_out,
                          "YYYY-MM-DD",
                          "DD-MM-YYYY"
                        )}
                        <span
                          className={
                            "border-white opacity-30 mx-1 border-r h-full"
                          }
                        ></span>
                        {reservation.guests_num
                          ? `- ${t("resv.guestCount")}: ${
                              reservation.guests_num
                            }`
                          : ""}
                        <span
                          className={
                            "border-white opacity-30 mx-1 border-r h-full"
                          }
                        ></span>
                        {reservation.tot_price
                          ? `- ${t(
                              "reservationNotes.price"
                            )}: ${reservation.tot_price.toFixed(2)}€`
                          : ``}
                      </div>
                    </motion.li>
                    <ReactTooltip
                      id={`soclose${reservation.code}`}
                      getContent={(dataTip) => <span>{dataTip}</span>}
                      effect="float"
                    />
                  </React.Fragment>
                );
              }
              return (
                <React.Fragment key={reservation.code}>
                  <motion.li
                    initial={{
                      left: -90,
                    }}
                    animate={{
                      left: 0,
                    }}
                    onClick={() => {
                      if (onView) onView(reservation);
                    }}
                    style={{
                      width: reservation.width + "%",
                      marginLeft: reservation.marginLeft + "%",
                      marginTop:
                        reservationList.length > 0
                          ? index * 24 + 5
                          : index * 24, // 30
                    }}
                    className={`${
                      IsSafari()
                        ? "transform skew-x-20Minus"
                        : "transform skew-x-20Minus"
                    } absolute cst-shadow truncate px-4 cursor-pointer flex flex-nowrap items-center w-full h-6 p-1 font-medium text-xs border-2 ${verifyBg(
                      reservation.checkin_status
                    )} ${
                      (reservation?.source === "BOOKING" ||
                        reservation?.source === "AIRBNB") &&
                      verifyBg(reservation.source)
                    }`}
                    data-tip={`${reservation.code} - ${
                      reservation.guest
                    } - ${getFormattedDate(
                      reservation.check_in,
                      "YYYY-MM-DD",
                      "DD-MM-YYYY"
                    )} -> ${getFormattedDate(
                      reservation.check_out,
                      "YYYY-MM-DD",
                      "DD-MM-YYYY"
                    )} - Check-In: ${verifyStatus(
                      reservation.checkin_status
                    )} ${
                      reservation.guests_num
                        ? `- ${t("resv.guestCount")}: ${reservation.guests_num}`
                        : ""
                    } ${
                      reservation.tot_price
                        ? `- ${t(
                            "reservationNotes.price"
                          )}: ${reservation.tot_price.toFixed(2)}€`
                        : ``
                    }`}
                    data-for={`soclose2${reservation.code}`}
                  >
                    <div
                      className={`w-full flex align-middle items-center truncate ${
                        IsSafari() ? "" : "transform skew-x-20"
                      }`}
                    >
                      {logoImageComp(reservation?.source)}
                      {`${reservation.code}
                    -
                    ${reservation.guest}
                    -
                    ${getFormattedDate(
                      reservation.check_in,
                      "YYYY-MM-DD",
                      "DD-MM-YYYY"
                    )}
                    -
                    ${getFormattedDate(
                      reservation.check_out,
                      "YYYY-MM-DD",
                      "DD-MM-YYYY"
                    )} - Check-In: ${verifyStatus(
                        reservation.checkin_status
                      )} ${
                        reservation.guests_num
                          ? `- ${t("resv.guestCount")}: ${
                              reservation.guests_num
                            }`
                          : ""
                      }`}
                      {reservation.tot_price
                        ? `- ${t(
                            "reservationNotes.price"
                          )}: ${reservation.tot_price.toFixed(2)}€`
                        : ``}
                    </div>
                  </motion.li>
                  <ReactTooltip
                    id={`soclose2${reservation.code}`}
                    getContent={(dataTip) => <span>{dataTip}</span>}
                    effect="float"
                  />
                </React.Fragment>
              );
            })}
          </ul>
        </div>
      </div>
      {/* view ratelist */}
      {tempObj && (
        <div
          className={`w-full flex align-middle justify-center cursor-pointer border-2 ${
            viewMore
              ? "bg-gray-300 hover:bg-gray-200"
              : "bg-gray-200 hover:bg-gray-300"
          }`}
          onClick={() => {
            setViewMore((prev) => !prev);
          }}
        >
          <p className="md:text-sm text-xs">
            {t("channelManager.rate_plan.rateList")}
          </p>
          {viewMore ? (
            <Icon name="arrow-up" size="10px" />
          ) : (
            <Icon name="arrow-down" size="10px" />
          )}
        </div>
      )}
      {/* per_person & per_room */}
      {tempObj &&
        viewMore &&
        Object.keys(tempObj)?.map((key, keyIdx) => {
          if (key === "per_person" || key === "per_room") {
            const keyPerPerson = key === "per_person";
            return tempObj[key as keyof typeof tempObj]?.map(
              (keyDi: any, idxOne: number) =>
                Object.keys(keyDi).map((desc: any, idi: number) => {
                  if (keyPerPerson) {
                    return (
                      <React.Fragment key={idi}>
                        <div
                          className={`hover:bg-white md:text-sm text-xs space-y-3 ${
                            keyDi[desc as keyof typeof keyDi].is_primary
                              ? ""
                              : viewAllPerPerson.includes(
                                  keyDi[desc as keyof typeof keyDi]?.title
                                )
                              ? ""
                              : "hidden"
                          }`}
                        >
                          <PerPersonRate
                            setChainedChannel={
                              keyDi[desc as keyof typeof keyDi].channel_rate &&
                              keyDi[desc as keyof typeof keyDi].channel_rate
                                .length > 0 &&
                              setChainedChannel
                            }
                            type="rate"
                            idi={idi}
                            idxOne={idxOne}
                            keyIdx={keyIdx}
                            containerRefPrice={containerRefPrice}
                            keyDi={keyDi[desc as keyof typeof keyDi]}
                            desc={desc}
                            days={days}
                            keys={key}
                            localName={local.name!}
                            tempObj={tempObj}
                            findActiveRevenue={
                              keyDi[desc as keyof typeof keyDi].is_primary &&
                              findActiveRevenue
                            }
                            revenueManager={
                              keyDi[desc as keyof typeof keyDi].is_primary &&
                              revenueManager
                            }
                            setIsDragging={setIsDragging}
                            setIsDraggingDays={setIsDraggingDays}
                            setIsDraggingPrice={setIsDraggingPrice}
                            setValueManager={setValueManager}
                            setRevenueModal={setRevenueModal}
                            isDraggingPrice={isDraggingPrice}
                            viewAllPerPerson={viewAllPerPerson}
                            setViewAllPerPerson={setViewAllPerPerson}
                            handleMouseDownRate={handleMouseDownRate}
                            handleMouseMoveRate={handleMouseMoveRate}
                            handleMouseUpRate={handleMouseUpRate}
                            selectedDivsPrice={selectedDivsPrice}
                            startPointPrice={startPointPrice}
                          />
                        </div>
                        <div className="hover:bg-white flex md:text-sm text-xs">
                          {keyDi[desc as keyof typeof keyDi].channel_rate &&
                            keyDi[desc as keyof typeof keyDi].channel_rate
                              .length > 0 &&
                            chainedChannel.includes(
                              keyDi[desc as keyof typeof keyDi]?.title
                            ) &&
                            keyDi[desc as keyof typeof keyDi].channel_rate.map(
                              (channel: any) => {
                                return (
                                  <PerPersonRate
                                    channelDerivate={channel.channel?.toLowerCase()}
                                    type="rate"
                                    containerRefPrice={null}
                                    days={days}
                                    idi={idi}
                                    idxOne={idxOne}
                                    keyIdx={keyIdx}
                                    keyDi={{
                                      ...channel,
                                      editable: false,
                                      currency:
                                        keyDi[desc as keyof typeof keyDi]
                                          .currency,
                                      occupancy:
                                        keyDi[desc as keyof typeof keyDi]
                                          .occupancy,
                                      is_primary: true,
                                    }}
                                    desc={desc}
                                    keys={key}
                                    localName={local.name!}
                                    tempObj={tempObj}
                                    findActiveRevenue={null}
                                    revenueManager={null}
                                    setIsDragging={null}
                                    setIsDraggingDays={null}
                                    setIsDraggingPrice={null}
                                    setValueManager={null}
                                    setRevenueModal={null}
                                    isDraggingPrice={null}
                                    handleMouseDownRate={null}
                                    handleMouseMoveRate={null}
                                    handleMouseUpRate={null}
                                    selectedDivsPrice={[]}
                                    startPointPrice={null}
                                    viewAllPerPerson={null}
                                    setViewAllPerPerson={null}
                                  />
                                );
                              }
                            )}
                        </div>
                        {selectedFilter?.map((filter: any) => {
                          if (filter.value === "min_stay_arrival") {
                            return (
                              <div
                                className={`hover:bg-white flex md:text-sm text-xs ${
                                  viewAllPerPerson.includes(
                                    keyDi[desc as keyof typeof keyDi]?.title
                                  )
                                    ? ""
                                    : "hidden"
                                }`}
                              >
                                <PerPersonRate
                                  type="min_stay_arrival"
                                  idi={idi}
                                  idxOne={idxOne}
                                  keyIdx={keyIdx}
                                  containerRefPrice={containerRefMinStayArrival}
                                  keyDi={keyDi[desc as keyof typeof keyDi]}
                                  desc={desc}
                                  days={days}
                                  keys={key}
                                  localName={local.name!}
                                  tempObj={tempObj}
                                  findActiveRevenue={
                                    keyDi[desc as keyof typeof keyDi]
                                      .is_primary && findActiveRevenue
                                  }
                                  revenueManager={
                                    keyDi[desc as keyof typeof keyDi]
                                      .is_primary && revenueManager
                                  }
                                  setIsDragging={setIsDragging}
                                  setIsDraggingDays={setIsDraggingDays}
                                  setIsDraggingPrice={setIsDraggingPrice}
                                  setValueManager={setValueManager}
                                  setRevenueModal={setRevenueModal}
                                  isDraggingPrice={isDraggingPrice}
                                  viewAllPerPerson={viewAllPerPerson}
                                  setViewAllPerPerson={setViewAllPerPerson}
                                  handleMouseDownRate={handleMouseDownRate}
                                  handleMouseMoveRate={handleMouseMoveRate}
                                  handleMouseUpRate={handleMouseUpRate}
                                  selectedDivsPrice={selectedDivsPrice}
                                  startPointPrice={startPointPrice}
                                />
                              </div>
                            );
                          }
                          if (filter.value === "max_stay") {
                            return (
                              <div
                                className={`hover:bg-white flex md:text-sm text-xs ${
                                  viewAllPerPerson.includes(
                                    keyDi[desc as keyof typeof keyDi]?.title
                                  )
                                    ? ""
                                    : "hidden"
                                }`}
                              >
                                <PerPersonRate
                                  type="max_stay"
                                  idi={idi}
                                  idxOne={idxOne}
                                  keyIdx={keyIdx}
                                  containerRefPrice={containerRefMaxStay}
                                  keyDi={keyDi[desc as keyof typeof keyDi]}
                                  desc={desc}
                                  days={days}
                                  keys={key}
                                  localName={local.name!}
                                  tempObj={tempObj}
                                  findActiveRevenue={
                                    keyDi[desc as keyof typeof keyDi]
                                      .is_primary && findActiveRevenue
                                  }
                                  revenueManager={
                                    keyDi[desc as keyof typeof keyDi]
                                      .is_primary && revenueManager
                                  }
                                  setIsDragging={setIsDragging}
                                  setIsDraggingDays={setIsDraggingDays}
                                  setIsDraggingPrice={setIsDraggingPrice}
                                  setValueManager={setValueManager}
                                  setRevenueModal={setRevenueModal}
                                  isDraggingPrice={isDraggingPrice}
                                  viewAllPerPerson={viewAllPerPerson}
                                  setViewAllPerPerson={setViewAllPerPerson}
                                  handleMouseDownRate={handleMouseDownRate}
                                  handleMouseMoveRate={handleMouseMoveRate}
                                  handleMouseUpRate={handleMouseUpRate}
                                  selectedDivsPrice={selectedDivsPrice}
                                  startPointPrice={startPointPrice}
                                />
                              </div>
                            );
                          }
                          if (filter.value === "closed_to_arrival") {
                            return (
                              <div
                                className={`hover:bg-white flex md:text-sm text-xs ${
                                  viewAllPerPerson.includes(
                                    keyDi[desc as keyof typeof keyDi]?.title
                                  )
                                    ? ""
                                    : "hidden"
                                }`}
                              >
                                <PerPersonRate
                                  type="closed_to_arrival"
                                  idi={idi}
                                  idxOne={idxOne}
                                  keyIdx={keyIdx}
                                  containerRefPrice={
                                    containerRefClosedToArrival
                                  }
                                  keyDi={keyDi[desc as keyof typeof keyDi]}
                                  desc={desc}
                                  days={days}
                                  keys={key}
                                  localName={local.name!}
                                  tempObj={tempObj}
                                  findActiveRevenue={
                                    keyDi[desc as keyof typeof keyDi]
                                      .is_primary && findActiveRevenue
                                  }
                                  revenueManager={
                                    keyDi[desc as keyof typeof keyDi]
                                      .is_primary && revenueManager
                                  }
                                  setIsDragging={setIsDragging}
                                  setIsDraggingDays={setIsDraggingDays}
                                  setIsDraggingPrice={setIsDraggingPrice}
                                  setValueManager={setValueManager}
                                  setRevenueModal={setRevenueModal}
                                  isDraggingPrice={isDraggingPrice}
                                  viewAllPerPerson={viewAllPerPerson}
                                  setViewAllPerPerson={setViewAllPerPerson}
                                  handleMouseDownRate={handleMouseDownRate}
                                  handleMouseMoveRate={handleMouseMoveRate}
                                  handleMouseUpRate={handleMouseUpRate}
                                  selectedDivsPrice={selectedDivsPrice}
                                  startPointPrice={startPointPrice}
                                />
                              </div>
                            );
                          }
                          if (filter.value === "closed_to_departure") {
                            return (
                              <div
                                className={`hover:bg-white flex md:text-sm text-xs ${
                                  viewAllPerPerson.includes(
                                    keyDi[desc as keyof typeof keyDi]?.title
                                  )
                                    ? ""
                                    : "hidden"
                                }`}
                              >
                                <PerPersonRate
                                  type="closed_to_departure"
                                  idi={idi}
                                  idxOne={idxOne}
                                  keyIdx={keyIdx}
                                  containerRefPrice={
                                    containerRefClosedToDeparture
                                  }
                                  keyDi={keyDi[desc as keyof typeof keyDi]}
                                  desc={desc}
                                  days={days}
                                  keys={key}
                                  localName={local.name!}
                                  tempObj={tempObj}
                                  findActiveRevenue={
                                    keyDi[desc as keyof typeof keyDi]
                                      .is_primary && findActiveRevenue
                                  }
                                  revenueManager={
                                    keyDi[desc as keyof typeof keyDi]
                                      .is_primary && revenueManager
                                  }
                                  setIsDragging={setIsDragging}
                                  setIsDraggingDays={setIsDraggingDays}
                                  setIsDraggingPrice={setIsDraggingPrice}
                                  setValueManager={setValueManager}
                                  setRevenueModal={setRevenueModal}
                                  isDraggingPrice={isDraggingPrice}
                                  viewAllPerPerson={viewAllPerPerson}
                                  setViewAllPerPerson={setViewAllPerPerson}
                                  handleMouseDownRate={handleMouseDownRate}
                                  handleMouseMoveRate={handleMouseMoveRate}
                                  handleMouseUpRate={handleMouseUpRate}
                                  selectedDivsPrice={selectedDivsPrice}
                                  startPointPrice={startPointPrice}
                                />
                              </div>
                            );
                          }
                          if (filter.value === "stop_sell") {
                            return (
                              <div
                                className={`hover:bg-white flex md:text-sm text-xs ${
                                  viewAllPerPerson.includes(
                                    keyDi[desc as keyof typeof keyDi]?.title
                                  )
                                    ? ""
                                    : "hidden"
                                }`}
                              >
                                <PerPersonRate
                                  type="stop_sell"
                                  idi={idi}
                                  idxOne={idxOne}
                                  keyIdx={keyIdx}
                                  containerRefPrice={containerRefStopSell}
                                  keyDi={keyDi[desc as keyof typeof keyDi]}
                                  desc={desc}
                                  days={days}
                                  keys={key}
                                  localName={local.name!}
                                  tempObj={tempObj}
                                  findActiveRevenue={
                                    keyDi[desc as keyof typeof keyDi]
                                      .is_primary && findActiveRevenue
                                  }
                                  revenueManager={
                                    keyDi[desc as keyof typeof keyDi]
                                      .is_primary && revenueManager
                                  }
                                  setIsDragging={setIsDragging}
                                  setIsDraggingDays={setIsDraggingDays}
                                  setIsDraggingPrice={setIsDraggingPrice}
                                  setValueManager={setValueManager}
                                  setRevenueModal={setRevenueModal}
                                  isDraggingPrice={isDraggingPrice}
                                  viewAllPerPerson={viewAllPerPerson}
                                  setViewAllPerPerson={setViewAllPerPerson}
                                  handleMouseDownRate={handleMouseDownRate}
                                  handleMouseMoveRate={handleMouseMoveRate}
                                  handleMouseUpRate={handleMouseUpRate}
                                  selectedDivsPrice={selectedDivsPrice}
                                  startPointPrice={startPointPrice}
                                />
                              </div>
                            );
                          }
                          return null;
                        })}
                      </React.Fragment>
                    );
                  }
                  return (
                    <React.Fragment key={idi}>
                      <PerRoomRate
                        setChainedChannel={
                          keyDi[desc as keyof typeof keyDi].channel_rate &&
                          keyDi[desc as keyof typeof keyDi].channel_rate
                            .length > 0 &&
                          setChainedChannel
                        }
                        type="rate"
                        containerRefPrice={containerRefPrice}
                        days={days}
                        idi={idi}
                        idxOne={idxOne}
                        keyIdx={keyIdx}
                        keyDi={keyDi[desc as keyof typeof keyDi]}
                        desc={desc}
                        keys={key}
                        localName={local.name!}
                        tempObj={tempObj}
                        findActiveRevenue={findActiveRevenue}
                        revenueManager={revenueManager}
                        setIsDragging={setIsDragging}
                        setIsDraggingDays={setIsDraggingDays}
                        setIsDraggingPrice={setIsDraggingPrice}
                        setValueManager={setValueManager}
                        setRevenueModal={setRevenueModal}
                        isDraggingPrice={isDraggingPrice}
                        handleMouseDownRate={handleMouseDownRate}
                        handleMouseMoveRate={handleMouseMoveRate}
                        handleMouseUpRate={handleMouseUpRate}
                        selectedDivsPrice={selectedDivsPrice}
                        startPointPrice={startPointPrice}
                      />
                      {keyDi[desc as keyof typeof keyDi].channel_rate &&
                        keyDi[desc as keyof typeof keyDi].channel_rate.length >
                          0 &&
                        chainedChannel.includes(
                          keyDi[desc as keyof typeof keyDi]?.title
                        ) &&
                        keyDi[desc as keyof typeof keyDi].channel_rate.map(
                          (channel: any) => {
                            return (
                              <PerRoomRate
                                channelDerivate={channel.channel?.toLowerCase()}
                                type="rate"
                                containerRefPrice={null}
                                days={days}
                                idi={idi}
                                idxOne={idxOne}
                                keyIdx={keyIdx}
                                keyDi={{
                                  ...channel,
                                  editable: false,
                                  currency:
                                    keyDi[desc as keyof typeof keyDi].currency,
                                  occupancy:
                                    keyDi[desc as keyof typeof keyDi].occupancy,
                                  is_primary: true,
                                }}
                                desc={desc}
                                keys={key}
                                localName={local.name!}
                                tempObj={tempObj}
                                findActiveRevenue={null}
                                revenueManager={null}
                                setIsDragging={null}
                                setIsDraggingDays={null}
                                setIsDraggingPrice={null}
                                setValueManager={null}
                                setRevenueModal={null}
                                isDraggingPrice={null}
                                handleMouseDownRate={null}
                                handleMouseMoveRate={null}
                                handleMouseUpRate={null}
                                selectedDivsPrice={[]}
                                startPointPrice={null}
                              />
                            );
                          }
                        )}
                      {selectedFilter?.map((filter: any) => {
                        if (filter.value === "min_stay_arrival") {
                          return (
                            <PerRoomRate
                              type="min_stay_arrival"
                              containerRefPrice={containerRefMinStayArrival}
                              days={days}
                              idi={idi}
                              idxOne={idxOne}
                              keyIdx={keyIdx}
                              keyDi={keyDi[desc as keyof typeof keyDi]}
                              desc={desc}
                              keys={key}
                              localName={local.name!}
                              tempObj={tempObj}
                              findActiveRevenue={findActiveRevenue}
                              revenueManager={revenueManager}
                              setIsDragging={setIsDragging}
                              setIsDraggingDays={setIsDraggingDays}
                              setIsDraggingPrice={setIsDraggingPrice}
                              setValueManager={setValueManager}
                              setRevenueModal={setRevenueModal}
                              isDraggingPrice={isDraggingPrice}
                              handleMouseDownRate={handleMouseDownRate}
                              handleMouseMoveRate={handleMouseMoveRate}
                              handleMouseUpRate={handleMouseUpRate}
                              selectedDivsPrice={selectedDivsPrice}
                              startPointPrice={startPointPrice}
                            />
                          );
                        }
                        if (filter.value === "max_stay") {
                          return (
                            <PerRoomRate
                              type="max_stay"
                              containerRefPrice={containerRefMaxStay}
                              days={days}
                              idi={idi}
                              idxOne={idxOne}
                              keyIdx={keyIdx}
                              keyDi={keyDi[desc as keyof typeof keyDi]}
                              desc={desc}
                              keys={key}
                              localName={local.name!}
                              tempObj={tempObj}
                              findActiveRevenue={findActiveRevenue}
                              revenueManager={revenueManager}
                              setIsDragging={setIsDragging}
                              setIsDraggingDays={setIsDraggingDays}
                              setIsDraggingPrice={setIsDraggingPrice}
                              setValueManager={setValueManager}
                              setRevenueModal={setRevenueModal}
                              isDraggingPrice={isDraggingPrice}
                              handleMouseDownRate={handleMouseDownRate}
                              handleMouseMoveRate={handleMouseMoveRate}
                              handleMouseUpRate={handleMouseUpRate}
                              selectedDivsPrice={selectedDivsPrice}
                              startPointPrice={startPointPrice}
                            />
                          );
                        }
                        if (filter.value === "closed_to_arrival") {
                          return (
                            <PerRoomRate
                              type="closed_to_arrival"
                              containerRefPrice={containerRefClosedToArrival}
                              days={days}
                              idi={idi}
                              idxOne={idxOne}
                              keyIdx={keyIdx}
                              keyDi={keyDi[desc as keyof typeof keyDi]}
                              desc={desc}
                              keys={key}
                              localName={local.name!}
                              tempObj={tempObj}
                              findActiveRevenue={findActiveRevenue}
                              revenueManager={revenueManager}
                              setIsDragging={setIsDragging}
                              setIsDraggingDays={setIsDraggingDays}
                              setIsDraggingPrice={setIsDraggingPrice}
                              setValueManager={setValueManager}
                              setRevenueModal={setRevenueModal}
                              isDraggingPrice={isDraggingPrice}
                              handleMouseDownRate={handleMouseDownRate}
                              handleMouseMoveRate={handleMouseMoveRate}
                              handleMouseUpRate={handleMouseUpRate}
                              selectedDivsPrice={selectedDivsPrice}
                              startPointPrice={startPointPrice}
                            />
                          );
                        }
                        if (filter.value === "closed_to_departure") {
                          return (
                            <PerRoomRate
                              type="closed_to_departure"
                              containerRefPrice={containerRefClosedToDeparture}
                              days={days}
                              idi={idi}
                              idxOne={idxOne}
                              keyIdx={keyIdx}
                              keyDi={keyDi[desc as keyof typeof keyDi]}
                              desc={desc}
                              keys={key}
                              localName={local.name!}
                              tempObj={tempObj}
                              findActiveRevenue={findActiveRevenue}
                              revenueManager={revenueManager}
                              setIsDragging={setIsDragging}
                              setIsDraggingDays={setIsDraggingDays}
                              setIsDraggingPrice={setIsDraggingPrice}
                              setValueManager={setValueManager}
                              setRevenueModal={setRevenueModal}
                              isDraggingPrice={isDraggingPrice}
                              handleMouseDownRate={handleMouseDownRate}
                              handleMouseMoveRate={handleMouseMoveRate}
                              handleMouseUpRate={handleMouseUpRate}
                              selectedDivsPrice={selectedDivsPrice}
                              startPointPrice={startPointPrice}
                            />
                          );
                        }
                        if (filter.value === "stop_sell") {
                          return (
                            <PerRoomRate
                              type="stop_sell"
                              containerRefPrice={containerRefStopSell}
                              days={days}
                              idi={idi}
                              idxOne={idxOne}
                              keyIdx={keyIdx}
                              keyDi={keyDi[desc as keyof typeof keyDi]}
                              desc={desc}
                              keys={key}
                              localName={local.name!}
                              tempObj={tempObj}
                              findActiveRevenue={findActiveRevenue}
                              revenueManager={revenueManager}
                              setIsDragging={setIsDragging}
                              setIsDraggingDays={setIsDraggingDays}
                              setIsDraggingPrice={setIsDraggingPrice}
                              setValueManager={setValueManager}
                              setRevenueModal={setRevenueModal}
                              isDraggingPrice={isDraggingPrice}
                              handleMouseDownRate={handleMouseDownRate}
                              handleMouseMoveRate={handleMouseMoveRate}
                              handleMouseUpRate={handleMouseUpRate}
                              selectedDivsPrice={selectedDivsPrice}
                              startPointPrice={startPointPrice}
                            />
                          );
                        }
                        return null;
                      })}
                    </React.Fragment>
                  );
                })
            );
          }
          return false;
        })}
      <RevenueTooltip revenueModal={revenueModal} />
      {revenueModalEdit.display && (
        <RevenueModal
          openModal={revenueModalEdit}
          setOpenModal={setRevenueModalEdit}
          refetchRevenueManager={refetchRevenueManager}
        />
      )}
    </div>
  );
}
