import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { SortingRule } from "react-table";
import BaseTable from "../components/BaseTable";
import Button from "../components/Button";
import PageWrapper from "../components/PageWrapper";
// import useBreadcrumbs from '../hooks/useBreadcrumbs';
import {
  exportListingCSV,
  fetchLocals,
  fetchPerms,
  /* fetchUserFilters,
  saveUserFilters, */
} from "../shared/queries";
import {
  LocalTable,
  TableFilterColumn,
  TableResponse,
  TableSort,
} from "../types";
import ReactTooltip from "react-tooltip";

export default function Apartments() {
  const { t } = useTranslation();
  const [count, setCount] = useState(0)

  const defaultFilters = [
    {
      key: "1",
      label: t("apartments.externalKey"),
      active: false,
      value: null,
      type: "text",
    },
    {
      key: "2",
      label: "Account",
      active: false,
      value: null,
      type: "text",
    },
    {
      key: "3",
      label: t("apartments.internalName"),
      active: false,
      value: null,
      type: "text",
    },
    {
      key: "4",
      label: t("apartments.publicName"),
      active: false,
      value: null,
      type: "text",
    },
    // { REMOVED
    //   key: '5',
    //   label: t('apartments.internalName'),
    //   active: false,
    //   value: null,
    //   type: 'text',
    // },
    {
      key: "5",
      label: t("general.city"),
      active: false,
      value: null,
      type: "text",
    },
    {
      key: "6",
      label: t("general.address"),
      active: false,
      value: null,
      type: "text",
    },
    {
      key: "7",
      label: t("apartments.deviceStatus"),
      active: false,
      value: null,
      type: "select",
      options: [
        { label: "Digital", value: "NOTINIT" },
        { label: "Online", value: "ACTIVE" },
        //{ label: t('apartments.warning'), value: 'WARNING' },
        { label: "Offline", value: "ERROR" },
        //{ label: t('apartments.closed'), value: 'CLOSED' },
      ],
    },
    {
      key: "8",
      label: t("apartments.paymentStatus"),
      active: false,
      value: null,
      type: "select",
      options: [
        { label: t("apartments.paySubscription"), value: "TOPAY_SUB" },
        { label: t("apartments.active"), value: "ACTIVE" },
        { label: t("apartments.payInstallation"), value: "TOPAY_INST" },
        { label: t("apartments.verifyPayment"), value: "PENDING_INST" },
        { label: t("apartments.verifySubscription"), value: "PENDING_SUB" },
        { label: t("general.error"), value: "PASTDUE" },
        { label: t("apartments.closed"), value: "CLOSED" },
      ],
    },
  ];

  const [newFilterState, setNewFilterState] = useState({
    name: "apt_filters",
    created: false,
    data: {
      filters: defaultFilters,
      currentPage: 1,
      hiddenColumns: [],
      sorting: [],
    },
  });

  const history = useHistory();
  const [accounts, setAccounts] = useState<{}>({});

  const { isLoading: isLoadingAccount } = useQuery("accounts", fetchPerms, {
    onSuccess: (data) => {
      const obj = {} as any;
      if (data && data.results) {
        data.results.forEach((account) => {
          obj[account.o_user_key] = account.o_name;
        });
        setAccounts(obj);
      }
    },
  });

  // useBreadcrumbs([{ label: t('apartments.apartments'), route: '/apartments' }]);

  const { data, isFetching } = useQuery<TableResponse<LocalTable>, Error>(
    [
      "apartments",
      newFilterState.data.currentPage,
      newFilterState.data.filters,
      newFilterState.data.sorting,
    ],
    () =>
      fetchLocals(
        newFilterState.data.filters,
        (newFilterState.data.currentPage - 1) * 15,
        newFilterState.data.sorting
      )
  );

  /* const { refetch: refetchAptFilter } = useQuery<any>(
    ["apartmentsFilters"],
    () => fetchUserFilters("apt_filters"),
    {
      onSuccess: (data) => {
        setNewFilterState((prev) => {
          return {
            ...prev,
            created: true,
            data: JSON.parse(data.results.value),
          };
        });
      },
      onError: () => {
        setNewFilterState((prev) => {
          return {
            ...prev,
            created: false,
          };
        });
      },
      retry: false,
    }
  ); */

  /* const saveAptFilter = useMutation(
    (body: { value: string; method: string }) =>
      saveUserFilters({ key: "apt_filters", value: body.value }, body.method),
    {
      onSuccess: () => {
        refetchAptFilter();
      },
    }
  ); */

  const downloadMutation = useMutation(exportListingCSV);

  function handleSorting(data: SortingRule<any>[]) {
    const apt_filters = localStorage.getItem('apt_filters');
    let dataPrev: any;
    if(apt_filters) {
      dataPrev = JSON.parse(apt_filters).value
    }
    const cols = [] as TableSort[];
    data.forEach((f) => {
      const columnKey = defaultFilters.find((df) => df.key === f.id);
      if (columnKey) {
        cols.push({
          columnIndex: columnKey.key,
          desc: f.desc || false,
        });
      }
    })
    if(dataPrev?.sorting[0]?.columnIndex !== '16' && count === 0){
      cols.push({
        columnIndex: dataPrev?.sorting[0]?.columnIndex,
        desc: dataPrev?.sorting[0]?.desc || false
      })
      setCount(1)
    }
    if (cols.length === 0) {
      cols.push({ columnIndex: "16", desc: true });
    }
    setNewFilterState((prev: any) => {
      const startObj = {
        ...prev,
        data: {
          ...dataPrev,
          sorting: cols,
        },
      };
      localStorage.setItem('apt_filters', JSON.stringify({
        value: startObj.data,
        method: startObj.created ? "PUT" : "POST",
      }))
      /* saveAptFilter.mutate({
        value: JSON.stringify(startObj.data),
        method: startObj.created ? "PUT" : "POST",
      }); */
      return startObj;
    });
  }

  const onFilterChange = (filters: TableFilterColumn[], page: number) => {
    console.log('onFilterChange', filters)
    setNewFilterState((prev: any) => {
      const startObj = {
        ...prev,
        data: {
          ...prev.data,
          filters: filters,
          currentPage: page,
        },
      };
      localStorage.setItem('apt_filters', JSON.stringify({
        value: startObj.data,
        method: startObj.created ? "PUT" : "POST",
      }))
      /* saveAptFilter.mutate({
        value: JSON.stringify(startObj.data),
        method: startObj.created ? "PUT" : "POST",
      }); */
      return startObj;
    });
    // refetch();
  };

  function getColumns() {
    return [
      {
        id: "2",
        Header: t("general.account") || "Account",
        accessor: (row: LocalTable) => (
          <>
            {
              <div
                className={
                  "bg-cyan-100 text-cyan-800inline-block text-xs font-medium px-2 rounded-2xl py-1"
                }
              >
                {(accounts as any)[row.user_key]}
              </div>
            }
          </>
        ),
      },
      {
        Header: t("apartments.publicName") || "",
        accessor: "public_name",
        id: "4",
      },
      {
        Header: t("apartments.internalName") || "",
        accessor: "name",
        id: "3",
      },
      {
        Header: t("general.address") || "",
        accessor: "address",
        id: "6",
      },
      {
        id: "5",
        // width: '15%',
        Header: t("general.city") || "",
        accessor: "city",
      },
      {
        id: "9",
        Header: "Monitor",
        disableSortBy: true,
        // width: '10%',
        accessor: (row: LocalTable) => (
          <>
            {row.monitor_status === "ACTIVE" && (
              <div
                className={
                  "bg-cyan-100 text-cyan-800 inline-block text-xs font-medium px-2 rounded-2xl py-1"
                }
              >
                Online
              </div>
            )}
            {row.monitor_status === "NOTINIT" && (
              <div
                className={
                  "bg-purple-100 text-purple-800 inline-block text-xs font-medium px-2 rounded-2xl py-1"
                }
              >
                Digital
              </div>
            )}
            {row.monitor_status === "ERROR" && (
              <div
                className={
                  "bg-red-100 text-red-800 inline-block text-xs font-medium px-2 rounded-2xl py-1"
                }
              >
                Offline
              </div>
            )}
          </>
        ),
      },
      {
        id: "8",
        Header: "Payment",
        // width: '10%',
        accessor: (row: LocalTable) => (
          <>
            {row.bootbill_status === "ACTIVE" && (
              <div
                className={
                  "bg-cyan-100 text-cyan-800 inline-block text-xs font-medium px-2 rounded-2xl py-1"
                }
              >
                {t("apartments.active")}
              </div>
            )}
            {row.bootbill_status === "TOPAY_SUB" && (
              <div
                className={
                  "bg-yellow-100 text-yellow-800 inline-block text-xs font-medium px-2 rounded-2xl py-1"
                }
              >
                {t("apartments.waitingPayment")}
              </div>
            )}
            {row.bootbill_status === "NOTINIT" && (
              <div
                className={
                  "bg-red-100 text-red-800 inline-block text-xs font-medium px-2 rounded-2xl py-1"
                }
              >
                Offline
              </div>
            )}
            {row.bootbill_status === "PENDING_INST" && (
              <div
                className={
                  "bg-red-100 text-red-800 inline-block text-xs font-medium px-2 rounded-2xl py-1"
                }
              >
                {t("apartments.verifyPayment")}
              </div>
            )}
            {row.bootbill_status === "PENDING_SUB" && (
              <div
                className={
                  "bg-red-100 text-red-800 inline-block text-xs font-medium px-2 rounded-2xl py-1"
                }
              >
                {t("apartments.verifySubscription")}
              </div>
            )}
            {row.bootbill_status === "PASTDUE" && (
              <div
                className={
                  "bg-red-100 text-red-800 inline-block text-xs font-medium px-2 rounded-2xl py-1"
                }
              >
                {t("general.error")}
              </div>
            )}
            {row.bootbill_status === "CLOSED" && (
              <div
                className={
                  "bg-red-100 text-red-800 inline-block text-xs font-medium px-2 rounded-2xl py-1"
                }
              >
                {t("apartments.closed")}
              </div>
            )}
          </>
        ),
      },
      {
        Header: t("apartments.paymentStatus"),
        id: "17",
        accessor: (row: LocalTable) => (
          <>
            <div
              data-tip={row.paymentStatus}
              className={
                row.paymentStatus
                  ? "text-left truncate"
                  : "bg-green-100 text-green-800 inline-block text-xs font-medium px-2 rounded-2xl py-1 text-left truncate"
              }
            >
              {row.paymentStatus ? row.paymentStatus : "Ok"}
            </div>
            <ReactTooltip />
          </>
        ),
      },
      /* 
      { REMOVED
        id: '8',
        Header: 'Bridge',
        // width: '10%',
        accessor: (row: LocalTable) => (
          <>
            {row.is_bridge ? (
              <div
                className={
                  'bg-cyan-600 inline-block text-xs font-medium px-2 rounded-2xl py-1'
                }
              >
                {t('general.yes')}
              </div>
            ) : (
              <div
                className={
                  'bg-gray-200 text-gray-700 inline-block text-xs font-medium px-2 rounded-2xl py-1'
                }
              >
                {t('general.no')}
              </div>
            )}
          </>
        ),
      }, */
    ];
  }

  useEffect(() => {
    const apt_filters = localStorage.getItem('apt_filters');
    if(apt_filters){
      setNewFilterState((prev) => {
        return {
          ...prev,
          created: true,
          data: JSON.parse(apt_filters).value,
        };
      });
    }
  }, [])

  return (
    <PageWrapper>
      <BaseTable
        identifierKey={"local_key"}
        // title={t('apartments.apartments')}
        data={!isLoadingAccount && !isFetching && data ? data.data : []}
        className={"mb-4"}
        loading={isLoadingAccount || isFetching}
        onSorting={handleSorting}
        currentPage={newFilterState.data.currentPage}
        pageCount={Math.ceil(parseInt(data?.recordsTotal || "0") / 15)}
        totalRows={parseInt(data?.recordsTotal || "0")}
        displayRows={15}
        renderButtons={() => (
          <>
            <Button
              onClick={() => history.push("/apartments/new")}
              icon="plus"
              color="primary"
              size="medium"
              padding="sm"
              label={t("general.newApartment")}
            />
            <Button
              onClick={() => {
                downloadMutation.mutate()
              }}
              loading={downloadMutation.isLoading}
              disabled={downloadMutation.isLoading}
              icon="document-download"
              color="primary"
              size="medium"
              padding="sm"
              label={t("general.listingExport")}
            />
          </>
        )}
        onRemoveFilters={() => {
          setNewFilterState((prev) => {
            const startObj = {
              ...prev,
              data: {
                filters: defaultFilters,
                hiddenColumns: [],
                currentPage: 1,
                sorting: [],
              },
            };
            localStorage.setItem('apt_filters', JSON.stringify({
              value: startObj.data,
              method: startObj.created ? "PUT" : "POST",
            }))
            
            /* saveAptFilter.mutate({
              value: JSON.stringify(startObj.data),
              method: startObj.created ? "PUT" : "POST",
            }); */
            return startObj;
          });
        }}
        onFilterChange={(filters, page) => {
          onFilterChange(filters, page || 1);
        }}
        onColumnChange={(columns) => {
          setNewFilterState((prev: any) => {
            const startObj = {
              ...prev,
              data: {
                ...prev.data,
                hiddenColumns: columns,
              },
            };
            localStorage.setItem('apt_filters', JSON.stringify({
              value: startObj.data,
              method: startObj.created ? "PUT" : "POST",
            }))
            /* saveAptFilter.mutate({
              value: JSON.stringify(startObj.data),
              method: startObj.created ? "PUT" : "POST",
            }); */
            return startObj;
          });
        }}
        filtersValue={newFilterState.data?.filters?.length > 0 ? newFilterState.data.filters : defaultFilters}
        filters={newFilterState.data?.filters?.length > 0 ? newFilterState.data.filters : defaultFilters}
        columns={getColumns()}
        onEdit={(id) => {
          history.push("/apartments/" + id + "#general");
        }}
        hiddenColumns={newFilterState.data.hiddenColumns}
      />
    </PageWrapper>
  );
}
